import useSWR, { KeyedMutator } from 'swr'
import { CustomError } from 'src/api/utils'
import { endpoints } from 'src/utils/axios'
import { getCurrentOrgId } from 'src/utils/local_storage'
import { PermissionsType } from '../../../models/permission/PermissionInterface'

type Permission = {
  permissionRecord: Record<string, PermissionsType>
  isLoading: boolean
  isError: boolean
  errorMessage?: string
  permissionMutator: KeyedMutator<Record<string, PermissionsType>>
}
export function usePermissionInfo(): Permission {
  const currentOrgId = getCurrentOrgId()
  const URL = endpoints.permissions.info

  const params = currentOrgId ? { organizationId: currentOrgId } : {}
  const { data, error, isLoading, mutate } = useSWR<Record<string, PermissionsType>, CustomError>([URL, params])

  const response = {
    permissionRecord: data as Record<string, PermissionsType>,
    isLoading,
    isError: !!error,
    errorMessage: error?.error as string,
    permissionMutator: mutate,
  }

  return response
}
