'use client'

import { useEffect, useMemo, useCallback, useContext } from 'react'

import { useRouter, usePathname } from 'src/routes/hooks'
import { setCrispUserContext, clearCrispContext, setCrispOrganizationContext } from 'integrations/crisp'
import { setDatadogUserContext, clearDatadogUserContext } from 'integrations/datadog'
import { setUserflowUserContext } from 'integrations/userflow'
import { setRudderstackUserContext } from 'integrations/rudderstack'
import { logoutUser, useCurrentUser, useUserflowSignature } from 'src/api/user'
import { useCurrentOrganization } from 'src/api/organization'
import { paths } from 'src/routes/paths'
import { IntegrationsContext } from 'src/providers/integrations'
import { Authorization_JWT_Key } from 'src/utils/axios'
import { getCurrentOrgId, setCurrentOrgId } from 'src/utils/local_storage'
import { Constants } from '../../../../../utils/constants'
import { AuthContext } from './auth-context'

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode
}

export function AuthProvider({ children }: Props): React.ReactNode {
  const integrationsContext = useContext(IntegrationsContext)

  const { appType } = integrationsContext
  const { currentUser, isLoading, mutateCurrentUser } = useCurrentUser()
  const { currentOrganization, mutateCurrentOrganization } = useCurrentOrganization()
  const { signature } = useUserflowSignature()

  const getLoginMethod = (): string => {
    if (appType === 'tab_app') {
      return Constants.JWT_LOGIN_METHOD
    }
    if (appType === 'slack_app') {
      return Constants.SLACK_LOGIN_METHOD
    }
    if (currentOrganization?.SSOEnabled) {
      return Constants.MICROSOFT_LOGIN_METHOD
    }
    return Constants.TEAMS_LOGIN_METHOD
  }

  const method = getLoginMethod()

  const router = useRouter()
  const currentPath = usePathname()

  useEffect(() => {
    // Call context only if currentUser is defined
    if (currentUser) {
      setDatadogUserContext(currentUser)
      setRudderstackUserContext(currentUser)
    }

    // Setting up the first organization id in local storage
    if (currentUser) {
      const currentOrgId = getCurrentOrgId()

      if (currentOrgId) {
        const checkIfUserBelongsToOrg = currentUser.organizationIds.includes(currentOrgId)
        if (!checkIfUserBelongsToOrg) {
          const firstOrgId = currentUser.organizationIds?.[0]
          if (firstOrgId) {
            setCurrentOrgId(firstOrgId)
          }
        }
      }

      if (!currentOrgId) {
        const firstOrgId = currentUser.organizationIds?.[0]
        if (firstOrgId) {
          setCurrentOrgId(firstOrgId)
        }
      }
    }
  }, [currentUser]) // Re-run the effect when currentUser changes

  useEffect(() => {
    // Call only if currentOrganization is defined
    if (currentOrganization && currentUser && currentUser.userOnboard) {
      setCrispOrganizationContext(currentOrganization)
      setCrispUserContext(currentUser)
    }
  }, [currentOrganization, currentUser]) // Re-run the effect when currentOrganization changes

  useEffect(() => {
    if (currentUser && signature && integrationsContext.userflowInitialized && currentUser.userOnboard) {
      setUserflowUserContext(currentUser, signature)
    }
  }, [currentUser, signature, integrationsContext.userflowInitialized])

  // send to signup if the user has not signed up
  useEffect(() => {
    // Check if currentUser is loaded and userOnboard is false
    if (!isLoading && currentUser && !currentUser.userOnboard && !currentPath.includes(paths.settingsTab.root)) {
      if (appType === 'web_app') {
        router.replace(paths.signup.root)
      }

      if (appType === 'tab_app' || localStorage.getItem(Authorization_JWT_Key)) {
        return
      }

      router.replace(paths.signup.root)
    }
  }, [appType, currentPath, currentUser, isLoading, router])

  const logout = useCallback(async () => {
    await logoutUser(router, method)
    mutateCurrentUser(undefined, true)
    mutateCurrentOrganization(undefined, true)
    clearDatadogUserContext()
    clearCrispContext()
  }, [mutateCurrentUser, mutateCurrentOrganization, router, method])

  const maybeRenderChildren = (): React.ReactNode => {
    if (isLoading) {
      return <></>
    }
    return children
  }

  const memoizedValue = useMemo(
    () => ({
      user: currentUser,
      method,
      authenticated: !!currentUser,
      isLoading,
      logout,
    }),
    [currentUser, method, isLoading, logout]
  )

  return <AuthContext.Provider value={memoizedValue}>{maybeRenderChildren()}</AuthContext.Provider>
}
