'use client'

import { useCallback, useMemo } from 'react'
import { useCurrentUser } from 'src/api/user'
import { AuthorizationContextType } from 'src/auth/types'
import { usePermissionInfo } from 'src/api/permission'
import { useCurrentOrganization } from 'src/api/organization'
import { getCurrentOrgId } from 'src/utils/local_storage'
import {
  AccountPermissionInfo,
  AudiencePermissionInfo,
  OrganizationPermissionInfo,
  ProgramPermissionInfo,
} from '../../../../../models/permission/PermissionInterface'
import { Constants } from '../../../../../utils/constants'
import { AuthorizationContext } from './authorization-context'

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode
}

export function AuthorizationProvider({ children }: Props): React.ReactNode {
  const { isLoading: isCurrentUserLoading } = useCurrentUser()
  const { currentOrganization, isLoading: isOrganizationLoading } = useCurrentOrganization()
  const { permissionRecord, isLoading, permissionMutator } = usePermissionInfo()
  const organizationId = getCurrentOrgId() as string

  const isAuthorizationLoading = useCallback(
    (): boolean => isCurrentUserLoading || isOrganizationLoading || isLoading,
    [isCurrentUserLoading, isLoading, isOrganizationLoading]
  )

  const reload = useCallback(async (): Promise<void> => {
    await permissionMutator()
  }, [permissionMutator])

  const isAuthorizedToAccessOrgResource = useCallback((): boolean => {
    if (!permissionRecord) {
      return false
    }

    const orgPermission: OrganizationPermissionInfo = permissionRecord[organizationId] as OrganizationPermissionInfo

    if (!orgPermission) {
      return false
    }

    if (orgPermission.owner || orgPermission.admin || orgPermission.manager) {
      return true
    }

    const accountPermission: AccountPermissionInfo = permissionRecord[orgPermission.accountId] as AccountPermissionInfo

    if (!accountPermission) {
      return false
    }

    return accountPermission.coversNewPrograms
  }, [organizationId, permissionRecord])

  const isAuthorizedToAccessProgram = useCallback(
    (programId: string): boolean => {
      if (!permissionRecord) {
        return false
      }

      const programPermission: ProgramPermissionInfo = permissionRecord[programId] as ProgramPermissionInfo

      if (!programPermission) {
        return false
      }

      if (programPermission.admin || programPermission.owner || programPermission.member) {
        return true
      }

      const orgPermission: OrganizationPermissionInfo = permissionRecord[organizationId] as OrganizationPermissionInfo

      if (!orgPermission) {
        return false
      }

      return orgPermission.admin || orgPermission.owner
    },
    [organizationId, permissionRecord]
  )

  const isAuthorizedToManageProgram = useCallback(
    (programId: string): boolean => {
      if (!permissionRecord) {
        return false
      }

      const programPermission: ProgramPermissionInfo = permissionRecord[programId] as ProgramPermissionInfo

      if (!programPermission) {
        return false
      }

      if (programPermission.admin || programPermission.owner) {
        return true
      }

      const orgPermission: OrganizationPermissionInfo = permissionRecord[organizationId] as OrganizationPermissionInfo

      if (!orgPermission) {
        return false
      }

      return orgPermission.admin || orgPermission.owner
    },
    [organizationId, permissionRecord]
  )

  const isProgramOwner = useCallback(
    (programId: string): boolean => {
      if (!permissionRecord) {
        return false
      }

      const orgPermission: OrganizationPermissionInfo = permissionRecord[organizationId] as OrganizationPermissionInfo
      const programPermission: ProgramPermissionInfo = permissionRecord[programId] as ProgramPermissionInfo

      if (!programPermission || !orgPermission) {
        return false
      }

      if (orgPermission.admin || orgPermission.owner) {
        return true
      }

      if (programPermission.owner && orgPermission.manager) {
        return true
      }

      return false
    },
    [organizationId, permissionRecord]
  )

  const canActivateProgramOnOrgFreePlan = useCallback(
    (programId: string): boolean => {
      if (!permissionRecord) {
        return false
      }

      const orgPermission: OrganizationPermissionInfo = permissionRecord[organizationId] as OrganizationPermissionInfo

      if (!orgPermission) {
        return false
      }

      const currentProgramPermission = permissionRecord[programId] as ProgramPermissionInfo
      if (!currentProgramPermission) {
        return false
      }

      const currentProgramType = currentProgramPermission.programType

      let totalActiveSimilarPrograms = 0
      orgPermission.programIds.forEach((pId) => {
        const prgPermission: ProgramPermissionInfo = permissionRecord[pId] as ProgramPermissionInfo

        if (prgPermission?.status === Constants.STATUS_ACTIVE && prgPermission?.programType === currentProgramType) {
          totalActiveSimilarPrograms += 1
        }
      })

      // If similar program exists, then we can't create a new program
      return totalActiveSimilarPrograms === 0
    },
    [organizationId, permissionRecord]
  )

  const isOrgOnPaidPlan = useCallback((): boolean => {
    if (!permissionRecord) {
      return false
    }

    const orgPermission: OrganizationPermissionInfo = permissionRecord[organizationId] as OrganizationPermissionInfo

    if (!orgPermission) {
      return false
    }

    return orgPermission.onPaidPlan
  }, [organizationId, permissionRecord])

  const wasProgramCreatedFromAPremiumTemplate = useCallback(
    (programId: string): boolean => {
      if (!permissionRecord) {
        return false
      }

      const programPermission: ProgramPermissionInfo = permissionRecord[programId] as ProgramPermissionInfo

      if (!programPermission) {
        return false
      }

      return programPermission.paidPlan
    },
    [permissionRecord]
  )

  const isParticipantOfProgram = useCallback(
    (programId: string): boolean => {
      if (!permissionRecord) {
        return false
      }

      const programPermission: ProgramPermissionInfo = permissionRecord[programId] as ProgramPermissionInfo

      if (!programPermission) {
        return false
      }

      return programPermission.member
    },
    [permissionRecord]
  )

  const isProgramAccountAvailable = useCallback(
    (programId: string): boolean => {
      if (!permissionRecord) {
        return false
      }

      const programPermission: ProgramPermissionInfo = permissionRecord[programId] as ProgramPermissionInfo

      if (!programPermission) {
        return false
      }

      if (programPermission.accountId) {
        return true
      }

      return false
    },
    [permissionRecord]
  )

  const isPaidProgram = useCallback(
    (programId: string): boolean => {
      if (!permissionRecord) {
        return false
      }

      const programPermission: ProgramPermissionInfo = permissionRecord[programId] as ProgramPermissionInfo

      if (!programPermission || !programPermission.accountId) {
        return false
      }

      const orgPermission: OrganizationPermissionInfo = permissionRecord[organizationId] as OrganizationPermissionInfo

      if (!orgPermission) {
        return false
      }

      return orgPermission.onPaidPlan
    },
    [organizationId, permissionRecord]
  )

  const isAuthorizedToManageOrganization = useCallback((): boolean => {
    if (!permissionRecord) {
      return false
    }

    if (!organizationId) {
      return false
    }

    const orgPermission: OrganizationPermissionInfo = permissionRecord[organizationId] as OrganizationPermissionInfo

    if (!orgPermission) {
      return false
    }

    return orgPermission.owner || orgPermission.admin
  }, [permissionRecord, organizationId])

  const hasAnyOrganizationRole = useCallback((): boolean => {
    if (!permissionRecord) {
      return false
    }

    if (!organizationId) {
      return false
    }

    const orgPermission: OrganizationPermissionInfo = permissionRecord[organizationId] as OrganizationPermissionInfo

    if (!orgPermission) {
      return false
    }

    return orgPermission.owner || orgPermission.admin || orgPermission.manager
  }, [permissionRecord, organizationId])

  const isOrganizationOwner = useCallback((): boolean => {
    const currentOrgId = getCurrentOrgId()

    if (!permissionRecord) {
      return false
    }

    if (!currentOrgId) {
      return false
    }

    const orgPermission: OrganizationPermissionInfo = permissionRecord[currentOrgId] as OrganizationPermissionInfo

    if (!orgPermission) {
      return false
    }

    return orgPermission.owner
  }, [permissionRecord])

  const isAuthorizedToManageAccount = useCallback(
    (accountId: string): boolean => {
      if (!permissionRecord) {
        return false
      }

      const accountPermission: AccountPermissionInfo = permissionRecord[accountId] as AccountPermissionInfo

      if (!accountPermission) {
        return false
      }

      return accountPermission.owner || accountPermission.admin
    },
    [permissionRecord]
  )

  const isAccountOwner = useCallback(
    (accountId: string): boolean => {
      if (!permissionRecord) {
        return false
      }

      const accountPermission: AccountPermissionInfo = permissionRecord[accountId] as AccountPermissionInfo

      if (!accountPermission) {
        return false
      }

      return accountPermission.owner
    },
    [permissionRecord]
  )

  const isAudienceOwner = useCallback(
    (audienceId: string): boolean => {
      if (!permissionRecord) {
        return false
      }

      const audiencePermission: AudiencePermissionInfo = permissionRecord[audienceId] as AudiencePermissionInfo

      if (!audiencePermission) {
        return false
      }

      return audiencePermission.owner
    },
    [permissionRecord]
  )

  const isAuthorizedToManageAudience = useCallback(
    (audienceId: string): boolean => {
      if (!permissionRecord) {
        return false
      }

      const audiencePermission: AudiencePermissionInfo = permissionRecord[audienceId] as AudiencePermissionInfo

      if (!audiencePermission) {
        return false
      }

      return audiencePermission.owner || audiencePermission.admin
    },
    [permissionRecord]
  )

  const isAuthorizedToUseAudienceOnProgram = useCallback(
    (audienceId: string): boolean => {
      if (!permissionRecord) {
        return false
      }

      const audiencePermission: AudiencePermissionInfo = permissionRecord[audienceId] as AudiencePermissionInfo

      if (!audiencePermission) {
        return false
      }

      const { owner: isUserOwner, admin: isUserAdmin } = audiencePermission

      if (!isUserOwner && !isUserAdmin) {
        return false
      }

      return true
    },
    [permissionRecord]
  )

  const isAudienceMember = useCallback(
    (audienceId: string): boolean => {
      if (!permissionRecord) {
        return false
      }

      const audiencePermission: AudiencePermissionInfo = permissionRecord[audienceId] as AudiencePermissionInfo

      if (!audiencePermission) {
        return false
      }

      return audiencePermission.owner || audiencePermission.admin || audiencePermission.member
    },
    [permissionRecord]
  )

  const isAdminOfAnyProgram = useCallback((): boolean => {
    if (!permissionRecord) {
      return false
    }

    const orgPermission = permissionRecord[organizationId] as OrganizationPermissionInfo

    if (!orgPermission) {
      return false
    }

    const programs = orgPermission.programIds

    // eslint-disable-next-line no-restricted-syntax
    for (const programId of programs) {
      const programPermission = permissionRecord[programId] as ProgramPermissionInfo
      if (programPermission && (programPermission.admin || programPermission.owner)) {
        return true
      }
    }

    return false
  }, [permissionRecord, organizationId])

  const isParticipantOfAnyProgram = useCallback((): boolean => {
    if (!permissionRecord) {
      return false
    }

    const orgPermission = permissionRecord[organizationId] as OrganizationPermissionInfo

    if (!orgPermission) {
      return false
    }

    const programs = orgPermission.programIds

    // eslint-disable-next-line no-restricted-syntax
    for (const programId of programs) {
      const programPermission = permissionRecord[programId] as ProgramPermissionInfo

      if (programPermission && programPermission.member) {
        return true
      }
    }

    return false
  }, [permissionRecord, organizationId])

  const isAuthorizedToHaveBillingTab = useCallback((): boolean => {
    // This function checks if the billing page is disabled for non billing admins

    if (currentOrganization && !currentOrganization?.isBillingPageDisabled) {
      return true
    }

    if (!permissionRecord) {
      return false
    }

    const orgPermission = permissionRecord[organizationId] as OrganizationPermissionInfo
    if (!orgPermission) {
      return false
    }

    const { accountId } = orgPermission
    const accountPermission = permissionRecord[accountId] as ProgramPermissionInfo
    if (accountPermission.admin || accountPermission.owner) {
      return true
    }

    return false
  }, [currentOrganization, permissionRecord, organizationId])

  const memoizedValue = useMemo(
    (): AuthorizationContextType => ({
      permissionRecord,
      isAuthorizationLoading,
      reload,

      isAuthorizedToAccessProgram,
      isAuthorizedToManageProgram,
      isAuthorizedToAccessOrgResource,
      canActivateProgramOnOrgFreePlan,
      isParticipantOfProgram,
      isOrgOnPaidPlan,
      wasProgramCreatedFromAPremiumTemplate,
      isPaidProgram,
      isProgramOwner,
      isAccountOwner,
      isProgramAccountAvailable,
      isAuthorizedToManageAccount,
      isAuthorizedToHaveBillingTab,
      isAudienceOwner,
      isAuthorizedToManageAudience,
      isAuthorizedToUseAudienceOnProgram,
      isAdminOfAnyProgram,
      isParticipantOfAnyProgram,
      isOrganizationOwner,
      isAuthorizedToManageOrganization,
      isAudienceMember,
      hasAnyOrganizationRole,
    }),
    [
      permissionRecord,
      isAuthorizationLoading,
      reload,
      isAuthorizedToAccessProgram,
      isAuthorizedToManageProgram,
      isAuthorizedToAccessOrgResource,
      canActivateProgramOnOrgFreePlan,
      isParticipantOfProgram,
      isOrgOnPaidPlan,
      wasProgramCreatedFromAPremiumTemplate,
      isPaidProgram,
      isProgramOwner,
      isAccountOwner,
      isProgramAccountAvailable,
      isAuthorizedToManageAccount,
      isAuthorizedToHaveBillingTab,
      isAudienceOwner,
      isAuthorizedToManageAudience,
      isAuthorizedToUseAudienceOnProgram,
      isAdminOfAnyProgram,
      isParticipantOfAnyProgram,
      isOrganizationOwner,
      isAuthorizedToManageOrganization,
      isAudienceMember,
      hasAnyOrganizationRole,
    ]
  )

  return <AuthorizationContext.Provider value={memoizedValue}>{children}</AuthorizationContext.Provider>
}
