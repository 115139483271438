import(/* webpackMode: "eager" */ "/tmp/build_dabfb24f/node_modules/simplebar-react/dist/simplebar.min.css");
;
import(/* webpackMode: "eager" */ "/tmp/build_dabfb24f/node_modules/react-lazy-load-image-component/src/effects/blur.css");
;
import(/* webpackMode: "eager" */ "/tmp/build_dabfb24f/node_modules/next/font/google/target.css?{\"path\":\"src/theme/typography.ts\",\"import\":\"Public_Sans\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"fallback\":[\"Helvetica\",\"Arial\",\"sans-serif\"]}],\"variableName\":\"primaryFont\"}");
;
import(/* webpackMode: "eager" */ "/tmp/build_dabfb24f/node_modules/next/font/google/target.css?{\"path\":\"src/theme/typography.ts\",\"import\":\"Barlow\",\"arguments\":[{\"weight\":[\"900\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"fallback\":[\"Helvetica\",\"Arial\",\"sans-serif\"]}],\"variableName\":\"secondaryFont\"}");
;
import(/* webpackMode: "eager", webpackExports: ["AuthorizationProvider"] */ "/tmp/build_dabfb24f/saas/frontend/src/auth/context/authorization/authorization-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthConsumer"] */ "/tmp/build_dabfb24f/saas/frontend/src/auth/context/passwordless/auth-consumer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthContext"] */ "/tmp/build_dabfb24f/saas/frontend/src/auth/context/passwordless/auth-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/tmp/build_dabfb24f/saas/frontend/src/auth/context/passwordless/auth-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_dabfb24f/saas/frontend/src/components/admin-console/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_dabfb24f/saas/frontend/src/components/animate/motion-lazy.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_dabfb24f/saas/frontend/src/components/progress-bar/progress-bar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_dabfb24f/saas/frontend/src/components/progress-bar/styles.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useSettingsContext"] */ "/tmp/build_dabfb24f/saas/frontend/src/components/settings/context/settings-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SettingsProvider"] */ "/tmp/build_dabfb24f/saas/frontend/src/components/settings/context/settings-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_dabfb24f/saas/frontend/src/components/settings/drawer/settings-drawer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_dabfb24f/saas/frontend/src/components/snackbar/snackbar-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_dabfb24f/saas/frontend/src/locales/localization-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_dabfb24f/saas/frontend/src/locales/use-locales.ts");
;
import(/* webpackMode: "eager", webpackExports: ["IdleTimerProvider"] */ "/tmp/build_dabfb24f/saas/frontend/src/providers/idle-timer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["IntegrationsProvider"] */ "/tmp/build_dabfb24f/saas/frontend/src/providers/integrations.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StripeProvider"] */ "/tmp/build_dabfb24f/saas/frontend/src/providers/stripe.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SWRProvider"] */ "/tmp/build_dabfb24f/saas/frontend/src/providers/swr.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_dabfb24f/saas/frontend/src/theme/index.tsx");
